'use strict';

/**
 * library
 */
import imagesLoaded from 'imagesloaded';
imagesLoaded.makeJQueryPlugin($);

/**
 * module
 */
import { anchor } from './module/_anchor';
import { disabled } from './module/_disabled';
import { fitimg } from './module/_fitimg';
import { hover } from './module/_hover';
import { nav } from './module/_nav';
import { carousel } from './module/_carousel';
import { modal } from './module/_modal';
import { inview } from './module/_inview';
import { scroll } from './module/_scroll';
import { tab } from './module/_tab';
import { comment } from './module/_comment';
import { section } from './module/_section';
import { trailer } from './module/_trailer';

/**
 * main
 */
const main = (() => {
    /**
     * loaded
     */
    const loaded = () => {
        inview.init();
        scroll.init();
        section.init();

        $('body').addClass('is-loaded');
        $('.js-auto-play').trigger('click');
    };

    /**
     * init
     */
    const init = () => {
        anchor.init();
        disabled.init();
        fitimg.init();
        hover.init();
        nav.init();
        carousel.init();
        modal.init();
        tab.init();
        comment.init();
        trailer.init();
    };

    // public
    return {
        init: init,
        loaded: loaded
    };
})();

$(() => {
    main.init();

    $('body')
        .imagesLoaded()
        .done(instance => {
            main.loaded();
        })
        .fail(() => {
            main.loaded();
        });
});
