'use strict';

export const scroll = (() => {
    const init = () => {
        $(window).on('scroll', () => {
            if ($(window).scrollTop() > 2160 - $(window).height()) {
                $('.hero').addClass('is-fixed');

                if ($(window).scrollTop() > 2160 - $(window).height() + $(window).height() / 2) {
                    $('.hero').addClass('is-passed');
                } else {
                    $('.hero').removeClass('is-passed');
                }
            } else {
                $('.hero').removeClass('is-fixed');
            }
        });

        if ($(window).scrollTop() > 2160 - $(window).height()) {
            $('.hero').addClass('is-fixed');

            if ($(window).scrollTop() > 2160 - $(window).height() + $(window).height() / 2) {
                $('.hero').addClass('is-passed');
            } else {
                $('.hero').removeClass('is-passed');
            }
        } else {
            $('.hero').removeClass('is-fixed');
        }
    };

    const control = event => {
        event.preventDefault();
    };

    const off = () => {
        document.addEventListener('mousewheel', control, { passive: false });
        document.addEventListener('touchmove', control, { passive: false });
    };

    const on = () => {
        document.removeEventListener('mousewheel', control, { passive: false });
        document.removeEventListener('touchmove', control, { passive: false });
    };

    return {
        init: init,
        off: off,
        on: on
    };
})();
