'use strict';

export const trailer = (() => {
    const init = () => {
        $('.js-trailer').each((index, element) => {
            const $trailer = $(element);
            const $stop = $trailer.find('.js-stop-trailer');

            $trailer.find('.js-play-trailer').each((index, element) => {
                const $this = $(element);

                $this.on('click', () => {
                    const $activeTrailer = $trailer.find('.js-player.is-active');
                    const activeTrailer = $activeTrailer.find('iframe')[0];
                    const $activeCaption = $trailer.find('.js-play-trailer.is-active');
                    const $nextTrailer = $trailer.find('.js-player:eq(' + index + ')');
                    const nextTrailer = $nextTrailer.find('iframe')[0];
                    const $nextCaption = $trailer.find('.js-play-trailer:eq(' + index + ')');

                    activeTrailer.contentWindow.postMessage('{"event": "command", "func": "stopVideo", "args": ""}', '*');
                    $activeTrailer.removeClass('is-active');
                    $activeCaption.removeClass('is-active');

                    $nextTrailer.addClass('is-active');
                    $nextCaption.addClass('is-active');
                    nextTrailer.contentWindow.postMessage('{"event": "command", "func": "playVideo", "args": ""}', '*');
                });
            });

            $stop.on('click', () => {
                $trailer
                    .find('.js-player.is-active')
                    .find('iframe')[0]
                    .contentWindow.postMessage('{"event": "command", "func": "stopVideo", "args": ""}', '*');
            });
        });
    };

    return {
        init: init
    };
})();
