'use strict';

export const tab = (() => {
    const init = () => {
        $('.tab').each((index, element) => {
            const $tab = $(element);
            const $profile = $tab.next('.profile');

            $tab.find('.tab__item').each((index2, element2) => {
                const $btn = $(element2);

                $btn.on('click', event => {
                    $tab.find('.tab__item.is-active').removeClass('is-active');
                    $btn.addClass('is-active');
                    $profile.find('.profile__item.is-active').removeClass('is-active');
                    $profile.find('.profile__item:eq(' + index2 + ')').addClass('is-active');
                });
            });
        });
    };

    return {
        init: init
    };
})();
