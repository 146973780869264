'use strict';

export const section = (() => {
    const init = () => {
        $('.section').each((index, element) => {
            const $title = $(element).find('.section-ttl');
            const $body = $(element).find('.section__body');
            const content_h = $title.height() + $body.height() + 40;

            if ($(window).height() > content_h) {
                $(element).addClass('is-centering');
            } else {
                $(element).removeClass('is-centering');
            }
        });
    };

    return {
        init: init
    };
})();
