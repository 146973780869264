'use strict';

import 'waypoints/lib/jquery.waypoints.min';

export const inview = (() => {
    const $inview = $('.js-inview');

    /**
     * init
     */
    const init = () => {
        $inview.each((index, element) => {
            const waypoint = new Waypoint({
                element: element,
                handler: direction => {
                    if (direction === 'down') {
                        $(element).addClass('is-inview');
                        $(element)
                            .prev('.js-inview')
                            .removeClass('is-inview');
                    } else {
                        $(element).removeClass('is-inview');
                        $(element)
                            .prev('.js-inview')
                            .addClass('is-inview');
                    }
                },
                offset: $(element).data('offset') ? $(element).data('offset') : '70%'
            });
        });

        $(window).on('resize', () => {
            setTimeout(() => {
                Waypoint.refreshAll();
            }, 100);
        });
    };

    return {
        init: init
    };
})();
