'use strict';

export const modal = (() => {
    const show = event => {
        const $this = $(event.currentTarget);
        const id = $this.data('modal');

        $('body').addClass('is-show-modal is-show-' + id);
    };

    const hide = event => {
        const $this = $(event.currentTarget);
        const id = $this.data('modal');

        $('body').removeClass('is-show-modal is-show-' + id);
    };

    const init = () => {
        $('.js-show-modal').on('click', event => {
            show(event);
        });

        $('.js-hide-modal').on('click', event => {
            hide(event);
        });
    };

    return {
        init: init
    };
})();
