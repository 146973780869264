'use strict';

export const comment = (() => {
    const init = () => {
        $('.profile__btn').each((index, element) => {
            const $btn = $(element);
            const $comment = $btn.prev('.comment');

            $btn.hover(
                event => {
                    $comment.addClass('is-show');
                },
                event => {
                    $comment.removeClass('is-show');
                }
            );
        });
    };

    return {
        init: init
    };
})();
