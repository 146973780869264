'use strict';

import 'slick-carousel';

export const carousel = (() => {
    /**
     * init
     */
    const init = () => {
        $('.js-carousel-cast').slick({
            speed: 600,
            cssEase: 'ease-out'
        });
    };

    return {
        init: init
    };
})();
